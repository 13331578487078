import React, { useContext, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { observer } from 'mobx-react';
import { StationStoreContext } from '@store/station';
import { isZipcode } from 'utils/location';
import { splitChunks } from 'utils/arr';
import Station from '@components/Station';
import IStation from '@interfaces/station';
import MapView from '@components/MapMarkers';

const searchPlaceHolder = '输入zipcode或者城市名查找小站';

const Stations = observer(() => {
  const [input, setInput] = useState('');
  const stationStore = useContext(StationStoreContext);
  const stations = stationStore.stations;
  let filteredStations = stations;

  const changeInputValue = (event: React.FormEvent<FormControlProps>) => {
    const { value } = event.target as HTMLInputElement;
    setInput(value);
  };

  if (input) {
    filteredStations = stations.filter((station) => {
      if (isZipcode(input)) {
        return station.zipcode.startsWith(input);
      } else {
        return station.address.city
          .toLowerCase()
          .startsWith(input.toLowerCase());
      }
    });
  }

  const stationsToRender = splitChunks<IStation>(filteredStations, 4);

  const renderStations = (someStations: IStation[][]) =>
    someStations.map((items, index) => (
      <Row key={`${index}`}>
        {items.map((station: IStation) => (
          <Col sm={3} key={station.name}>
            <Station station={station} />
          </Col>
        ))}
      </Row>
    ));

  return (
    <div className='padding2em1em' id={'stations'}>
      <Container>
        <Row>
          <Col>
            <InputGroup className='mb-3'>
              <FormControl
                placeholder={searchPlaceHolder}
                aria-label={searchPlaceHolder}
                aria-describedby='basic-addon1'
                onChange={changeInputValue}
              />
              <InputGroup.Append>
                <Button variant='warning'>查找附近小站</Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <MapView stations={filteredStations} />
          </Col>
        </Row>
        <Row className='padding1top'>
          <Col></Col>
        </Row>
        {renderStations(stationsToRender)}
        {!stationsToRender[0].length && (
          <React.Fragment>
            <h5>{'并未发现直接匹配站点, 请浏览所有站查看附近小站'}</h5>
            {renderStations(splitChunks<IStation>(stations, 4))}
          </React.Fragment>
        )}
      </Container>
    </div>
  );
});

export default Stations;
