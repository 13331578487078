import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '@assets/image/logo/SG_yellow.png';

import about from '@assets/data/about.json';

export const Footer = () => {
  const items = about.data;
  return (
    <footer className='pt-4 pt-md-5 border-top'>
      <Container>
        <Row>
          <Col key={'logo'} xs={4}>
            <img className='mb-2' src={logo} alt='' width='50' height='50' />
            <small className='d-block mb-3 '>
              &copy; Bluejay, Inc. {new Date().getFullYear()}
            </small>
          </Col>
          {items.map((item) => {
            return (
              <Col key={item.category}>
                <h5>{item.category}</h5>
                <ul className='list-unstyled text-small'>
                  {item.links.map((link) => (
                    <li key={link.name}>
                      <a href={link.link}>{link.name}</a>
                    </li>
                  ))}
                </ul>
              </Col>
            );
          })}
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
