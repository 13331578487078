import React, { useEffect, useContext, useState } from 'react';
import Amplify from 'aws-amplify';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import Home from './pages/Home';
import Express from './pages/Express';
import Save from './pages/Save';
import PriceCalc from './pages/PriceCalc';
import UserGuide from './pages/UserGuide';
import Stations from './pages/Stations';
import Invite from 'pages/Invite';

import Privacy from './pages/About/Privacy';
import Team from './pages/About/Team';

import awsconfig from './aws-exports';

import { StationStoreContext } from '@store/station';
import { getGroups } from '@service/index';
import Footer from '@components/Footer';
import './App.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import wechatCusomerService from '@assets/image/download/WechatCusomerService.png';
import logo from '@assets/image/logo/SG_trans.png';
import ScrollToTop from '@components/ScrollToTop';

Amplify.configure(awsconfig);

const App: React.FC = observer(() => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const stationStore = useContext(StationStoreContext);
  useEffect(() => {
    getGroups().then((groups) => stationStore.saveStations(groups));
  }, [stationStore]);
  return (
    <Router>
      <ScrollToTop>
        <Navbar bg='dark' variant='dark' expand='md'>
          <img className='logo' src={logo} alt='logo' />
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav activeKey='/' className='navbar-nav-hz'>
              <Nav.Item>
                <Link to='/' className='nav-link'>
                  首页
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to='/guide' className='nav-link'>
                  新手指南
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to='/express' className='nav-link'>
                  松果极速
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to='/save' className='nav-link'>
                  松果极省
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to='/station' className='nav-link'>
                  松果小站
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to='/price' className='nav-link'>
                  运费计算
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to='/invite' className='nav-link'>
                  加入我们
                </Link>
              </Nav.Item>
            </Nav>
            <Form inline>
              <Button
                variant='outline-info'
                className='btn-outline-warning'
                onClick={handleShow}
              >
                客服
              </Button>
            </Form>
          </Navbar.Collapse>
        </Navbar>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName='modal-90w'
          aria-labelledby='exampleModalCenterTitle'
        >
          <Modal.Header closeButton>
            <Modal.Title id='modal-title-customer-service'>
              <h5 className='modal-title text-muted'>
                客服微信 <i className='fab fa-weixin'></i> WeChat
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              className='img-fluid'
              src={wechatCusomerService}
              alt='wechat service'
            />
          </Modal.Body>
          <Modal.Footer>
            <p className='text-muted'>
              <i className='fab fa-weixin'></i> 客服将尽快与您联系
            </p>
          </Modal.Footer>
        </Modal>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/guide' component={UserGuide} />
          <Route path='/express' component={Express} />
          <Route path='/save' component={Save} />
          <Route path='/price' component={PriceCalc} />
          <Route path='/station' component={Stations} />
          <Route path='/privacy' component={Privacy} />
          <Route path='/team' component={Team} />
          <Route path='/invite' component={Invite} />
        </Switch>
        <Footer />
      </ScrollToTop>
    </Router>
  );
});

export default App;
