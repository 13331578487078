import { EXPRESS_SHIP_RATE, SAVE_SHIP_RATE } from 'constants/constants';
export function formatPrice(v: number) {
  return (v / 100).toFixed(2);
}

const EXPRESS_RATE = EXPRESS_SHIP_RATE;
const SAVE_RATE = SAVE_SHIP_RATE;

const weightRound = (weight: number) => {
  if (weight <= 1) {
    return 1;
  }
  return Math.floor(weight) + 0.1 <= weight
    ? Math.ceil(weight)
    : Math.floor(weight);
};

const calcRate = (rate: number, weight: number, volumetricWeight: number) => {
  const costWeight = weightRound(Math.max(weight, volumetricWeight) / 100);
  const cost = Math.round(costWeight * rate * 100);
  return cost;
};

export const expressFormula = (weight: number, volumetricWeight: number) => {
  return calcRate(EXPRESS_RATE, weight, volumetricWeight);
};

const SAVE_RATE_1_DOLLAR_PER_WEIGHT = SAVE_RATE / 10; // $0.6 for 100
const MIN_CHARGE_WEIGHT = 1000; // 1kg

export const saveFormula = (weight: number, volumetricWeight: number) => {
  const costWeight = Math.max(weight, volumetricWeight, MIN_CHARGE_WEIGHT);
  return Math.round(costWeight * SAVE_RATE_1_DOLLAR_PER_WEIGHT);
};

export function expressVolumneWeight(
  length: number,
  width: number,
  height: number,
) {
  return (length * width * height) / 5;
}

export function saveVolumneWeight(
  length: number,
  width: number,
  height: number,
) {
  return (length * width * height) / 6;
}
