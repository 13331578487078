import React, { useState, ChangeEvent } from 'react';
import { EXPRESS_SHIP_RATE, SAVE_SHIP_RATE } from 'constants/constants';
import {
  formatPrice,
  expressVolumneWeight,
  saveVolumneWeight,
  expressFormula,
  saveFormula,
} from 'utils/num';

interface PriceCalcProps {
  hideExpress?: boolean;
  hideSave?: boolean;
}

export const PriceCalc = (props: PriceCalcProps) => {
  let [length, setLength] = useState(0);
  let [width, setWidth] = useState(0);
  let [height, setHeight] = useState(0);
  let [weight, setWeight] = useState(0);
  const { hideExpress, hideSave } = props;

  const updateLength = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setLength(parseInt(value) || 0);
  };

  const updateWidth = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setWidth(parseInt(value) || 0);
  };

  const updateHeight = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setHeight(parseInt(value) || 0);
  };

  const updateWeight = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setWeight(parseInt(value) || 0);
  };

  const name = hideSave ? '极速' : '极省';

  return (
    <React.Fragment>
      <div id='calc' className='padding2em' style={styles.calculator}>
        <h4>
          <i className='fas fa-calculator'></i> {name}运费计算器
        </h4>
        <br />
        <form>
          <div className='form-group'>
            <label htmlFor='packageLength'>
              长 <span className='text-muted'>cm厘米</span>
            </label>
            <input
              className='form-control'
              placeholder='包裹长度'
              aria-label='包裹长度'
              aria-describedby='basic-addon1'
              value={length || ''}
              min={1}
              onChange={updateLength}
            />
          </div>

          <div className='form-group'>
            <label htmlFor='packageWidth'>
              宽 <span className='text-muted'>cm厘米</span>
            </label>
            <input
              type='number'
              className='form-control'
              id='packageWidth'
              value={width || ''}
              placeholder='包裹宽度'
              onChange={updateWidth}
            />
          </div>

          <div className='form-group'>
            <label htmlFor='packageHeight'>
              高 <span className='text-muted'>cm厘米</span>
            </label>
            <input
              type='number'
              className='form-control'
              id='packageHeight'
              value={height || ''}
              placeholder='包裹高度'
              onChange={updateHeight}
            />
          </div>

          <div className='form-group'>
            <label htmlFor='packageWeight'>
              重量 <span className='text-muted'>g克</span>
            </label>
            <input
              type='number'
              className='form-control'
              id='packageWeight'
              value={weight || ''}
              placeholder='包裹重量'
              onChange={updateWeight}
            />
          </div>

          <br />
          <h4>
            <i className='fas fa-dollar-sign'></i> 估算结果
          </h4>
          <br />

          <div className='form-group'>
            <label htmlFor='volume'>
              体积 <span className='text-muted'>cm³立方厘米</span>
            </label>
            <input
              readOnly
              type='number'
              className='form-control'
              id='volume'
              value={length * height * width}
              min='0'
              required
              placeholder='体积'
            />
          </div>

          {!hideExpress && (
            <div className='form-group'>
              <label htmlFor='fastFinalTotal'>
                {' '}
                <i className='fas fa-plane-departure'></i> 极速{' '}
                <span className='text-muted'>
                  (美元${EXPRESS_SHIP_RATE}/100克)
                </span>
              </label>
              <input
                readOnly
                type='text'
                className='form-control'
                id='fastFinalTotal'
                value={formatPrice(
                  expressFormula(
                    weight,
                    expressVolumneWeight(length, width, height),
                  ),
                )}
                placeholder='估算结果'
              />
            </div>
          )}

          {!hideSave && (
            <div className='form-group'>
              <label htmlFor='fastFinalTotal'>
                <i className='fas fa-dolly-flatbed'></i> 极省{' '}
                <span className='text-muted'>
                  (美元${SAVE_SHIP_RATE} / 公斤kg)
                </span>
              </label>
              <input
                readOnly
                type='text'
                className='form-control'
                id='savingFinalTotal'
                value={formatPrice(
                  saveFormula(weight, saveVolumneWeight(length, width, height)),
                )}
                placeholder='估算结果'
              />
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

const styles = {
  calculator: {
    backgroundColor: '#eeeeee',
    border: '1px solid #ddd',
  },
};

export default PriceCalc;
