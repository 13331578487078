import React from 'react';
import Card from 'react-bootstrap/Card';

const Privacy = () => {
  return (
    <div>
      <Card>
        <div className='padding2em'>
          <Card.Title>
            <h3>隐私政策</h3>
          </Card.Title>
          <Card.Text>
            <div className='vPrivacy-content'>
              <p className='vPrivacy-intro'>
                欢迎使用松果网。您在使用我们的服务时，我们可能会收集和使用您的相关信息。为了保障您的权益，让您能安心的使用本网站和应用，特此向您说明本网站的隐私保护政策。
              </p>
              <div className='vPrivacy-desc'>
                <dl>
                  <dt>引言</dt>
                  <dd>
                    松果网是服务于海外华人共享物流的应用。松果网尊重并保护您的隐私。您使用我们的服务，即意味着同意我们按照本《隐私政策》收集和使用您的相关信息。
                  </dd>
                </dl>
                <dl>
                  <dt>隐私权保护政策的使用范围</dt>
                  <dd>
                    隐私权保护政策内容，包括本网站如何处理在您使用网站服务时收集到的个人信息。隐私保护政策不适用于本网站以外的相关连接网站，也不适用于非本网站所委托或参与管理的人员。
                  </dd>
                </dl>
                <dl>
                  <dt>个人信息的收集</dt>
                  <dd>
                    您在用微信登录时，微信平台将会提供微信用户共享信息，包括昵称和性别，但不包括微信号。您在注册松果账号的时候，可以提供微信号。我们将会采集并存储您的微信昵称，性别和微信号信息（如果您提供）。松果网还使用AWS
                    Analytics来记录用户的位置，浏览器类型等信息。
                  </dd>
                </dl>
                <dl>
                  <dt>个人信息的使用</dt>
                  <dd>
                    您的个人信息仅限于松果网使用以下用途:
                    1.验证用户输入数据的真实性，2.提供更好更精准的服务，3.防止恶意用户的欺诈行为。
                    未经您同意，松果网不会与第三方分享和交易您的个人信息。
                  </dd>
                </dl>
                <dl>
                  <dt>个人信息的安全</dt>
                  <dd>
                    本网站主机设有防火墙，使用SSL等各项安全设备及措施。通过这些严格措施努力保护您的信息不被未经授权的访问、使用或泄漏。
                  </dd>
                </dl>
                <dl>
                  <dt>未成年人信息保护</dt>
                  <dd>
                    松果网非常重视对未成年人信息的保护。若您是18周岁以下的未成年人，在使用前事先取得您的家长或法定监护人的同意。
                  </dd>
                </dl>
                <dl>
                  <dt>隐私保护的修正</dt>
                  <dd>
                    本网站隐私保护政策应需求随时修正，修正后的条款将及时发布到网站上。
                  </dd>
                </dl>
              </div>
            </div>
          </Card.Text>
        </div>
      </Card>
    </div>
  );
};

export default Privacy;
