import React from 'react';
import { EXPRESS_SHIP_RATE } from 'constants/constants';

import packageVolume from '@assets/image/branding/packageVolume1.jpg';

export const ExpressPriceDetail = () => {
  return (
    <React.Fragment>
      <p>
        系统自动计算结果基于如下参数：
        <br />
        <br />
        <b>* 运费单价： ${EXPRESS_SHIP_RATE} / 100g </b>{' '}
        <span className='text-muted'>
          {' '}
          （即 每100克{EXPRESS_SHIP_RATE}美元）
        </span>
        。
        <br />
        <br />
        * 不足100g的向上取整收费，如：120g 收费重量为200g。
        <br />
        <br />
        * 实际重量和抛货重量取比较重的那个：
        <br />
        抛货重量（公斤）计算公式：<b>长(cm) x 宽(cm) x 高(cm) / 5000</b>。
        <br />
        <br />* 如果单个包裹收费重量大于2kg, 价格自动变为<b>9折</b> 。
        <br />
        <br />
        <img src={packageVolume} className='img-fluid' alt='volume' />
        <br />
        <br />
        <span className='text-muted'>
          例如：长33厘米 x 宽33厘米 x 高33厘米 / 5000 = 7.1874 公斤。
          <br />
          运费：7187克 取算7200克；7200克 x 1.2美元 / 100克 x 90% (打九折) =
          77.76 美元。
        </span>
      </p>
      <br />

      <p>
        * 包裹中如有片状面膜，每单收取3刀额外费用，重量不限。
        <br />
        <br />
        * 带有内置电池的产品，每单收取3刀额外费用，重量不限。
        <br />
        <br />* 包裹单边长超过1.2米，收取<b>超长费$180</b>。
        <br />
      </p>
    </React.Fragment>
  );
};

export default ExpressPriceDetail;
