import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

import step1 from '@assets/image/operations/process-online-01.svg';
import step2 from '@assets/image/operations/process-storage-01.svg';
import step3 from '@assets/image/operations/process-get-01.svg';

const Steps = () => {
  return (
    <div className='bg-light padding2em1em'>
      <Container>
        <Row>
          <Col md>
            <Card>
              <Card.Img variant='top' src={step1} />
              <Card.Body>
                <Card.Title>
                  <h1>1</h1>
                </Card.Title>
                <Card.Text>
                  <p>
                    邮寄电商包裹或自己包裹至松果广州仓库，由仓库统一收取。
                    <br />
                    <Link to={'/guide'} className='hover_effect'>
                      <i className='fas fa-shipping-fast'></i> 货运列表/禁运清单
                    </Link>
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md>
            <Card>
              <Card.Img variant='top' src={step2} />
              <Card.Body>
                <Card.Title>
                  <h1>2</h1>
                </Card.Title>
                <Card.Text>
                  <p>
                    当你的包裹到达仓库之后，请在松果app上面支付货运费用。
                    <br />
                    <Link to={'/price'} className='hover_effect'>
                      <i className='fas fa-calculator'></i> 运费计算器
                    </Link>
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md>
            <Card>
              <Card.Img variant='top' src={step3} />
              <Card.Body>
                <Card.Title>
                  <h1>3</h1>
                </Card.Title>
                <Card.Text>
                  <p>
                    当货物到达你的松果小站，请前往您指定的小站取货。
                    <br />
                    <Link to={'/station'} className='hover_effect'>
                      <i className='fas fa-map-marked-alt'></i>{' '}
                      查找我附近的取货小站
                    </Link>
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Steps;
