import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PriceCalc from '@components/PriceCalc';
import ExpressPriceDetail from '@components/ExpressPriceDetail';

export const Price = () => {
  return (
    <div className='border-top '>
      <Container className='padding2em1em'>
        <Row>
          <Col sm={8}>
            <h4>
              <i className='fas fa-plane-departure'></i> 极速空运
            </h4>
            <br />
            <ExpressPriceDetail />
          </Col>
          <Col>
            <PriceCalc hideSave />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Price;
