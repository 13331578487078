import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

import processSvg from '@assets/image/operations/process-get-01.svg';

const Introduction = () => {
  return (
    <Container className='padding2em1em'>
      <Row>
        <Col sm={6} className='padding1em'>
          <img src={processSvg} className='img-fluid' alt='ship package' />
        </Col>
        <Col sm={6}>
          <h1>松果极省</h1>
          <p>
            松果极省线是松果针对非急用用户设计的适合中大型包裹运输的定制线路。
            每周发货，货物发出后一般4-6周到达取货点，价格是松果极速的一半不到，是一条性价比极高的线路。
          </p>
          <ul className='h5 intro-list'>
            <li>4-6星期到达</li>
            <li>
              九种开放品名拼箱：
              <span className='text-muted'>
                书本，寝具，布艺，铁质用具，衣服，鞋子，塑料制品，纸艺包装，文教用品
              </span>
              每个包裹按最低1kg收费，超过1kg的包裹，书本按1.5/500g计费，其它6类按$1/200g收费。
              无额外服务费和报关费
            </li>
            <li>
              非7个常规品类，凡符合允许范围之物品，每个包裹按最低3kg收费。超过3kg的包裹按$2.5/500g收费，无额外服务费和保费
            </li>
            <li>所有包裹都不合并</li>
            <li>为15公斤以上大包裹用户提供免费送货上门服务</li>
            <li>
              凡是15公斤以下包裹请前往
              <Link to={'/station'}>
                <i className='fas fa-store'></i> 松果取货小站
              </Link>
            </li>
            <li>微信客服方便联系</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Introduction;
