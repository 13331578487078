import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PriceCalc from '@components/PriceCalc';
import SavePriceDetail from '@components/SavePriceDetail';

export const Price = () => {
  return (
    <div className='border-top '>
      <Container className='padding2em1em'>
        <Row>
          <Col sm={8}>
            <h4>
              <i className='fas fa-dolly-flatbed'></i> 极省海运
            </h4>
            <br />
            <SavePriceDetail />
          </Col>
          <Col sm={4}>
            <PriceCalc hideExpress />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <b>备注</b>:
              <ul>
                <li>
                  因为本线路是报关线路，对物品要求更为严格。无法确认是否属于禁运范围的产品，请联系松果客服确认能承运后再邮寄至仓库。为避免因不符合规定而引起的货物滞留问题，请在邮寄包裹前先行咨询我们的客服
                </li>
                <li>
                  松果极省的拼箱流程和极速基本一致，只有对填单物品的描述要求更为精确。为了不影响我们的报关速度，请务必准确填单，不要瞒报或乱报。用户需如实申报品名和数量，如申报与货物不一致，造成延误以及扣关甚至退运而产生的所有责任和相关费用将由用户承担
                </li>
              </ul>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Price;
