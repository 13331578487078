import React from 'react';
import Card from 'react-bootstrap/Card';

const Privacy = () => {
  return (
    <div>
      <Card>
        <div className='padding2em'>
          <Card.Title>
            <h3>团队</h3>
          </Card.Title>
          <Card.Text>
            团队建设中，欢迎你的加入。如有兴趣，请联系松果客服。
          </Card.Text>
        </div>
      </Card>
    </div>
  );
};

export default Privacy;
