import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

import { EXPRESS_SHIP_RATE, SAVE_SHIP_RATE } from 'constants/constants';
import flight from '@assets/image/branding/flight1.png';
import ship from '@assets/image/branding/shipSmall2.png';

const Shipment = () => {
  return (
    <Container className='padding2em1em'>
      <Row className={'bg-white align-items-center'}>
        <Col md>
          <img src={ship} alt={'freight'} className='img-fluid' />
        </Col>
        <Col md className='padding2em'>
          <h3 className='d-inline-block mb-2'>松果极省</h3>
          <div className='mb-1 '>
            <b>${SAVE_SHIP_RATE}/kg</b>
          </div>
          中大包裹，三周到达，开放品名1kg起运
          <br />
          <br />
          <Link
            to='/save'
            className='stretched-link btn btn-warning color-white'
          >
            详细信息
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md className='padding2em'>
          <h3 className='d-inline-block mb-2'>松果极速</h3>
          <div className='mb-1'>
            <b>${EXPRESS_SHIP_RATE}/100g</b>
          </div>
          3-7天到达，100克起运，每周发货
          <br />
          <br />
          <Link
            to='/express'
            className='stretched-link btn btn-warning color-white'
          >
            详细信息
          </Link>
        </Col>
        <Col md>
          <img src={flight} alt={'flight'} className='img-fluid' />
        </Col>
      </Row>
    </Container>
  );
};

export default Shipment;
