import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import packageVolume from '@assets/image/branding/packageVolume1.jpg';
import savecatalog from '@assets/data/savecatalog.json';
import { SAVE_SHIP_RATE } from 'constants/constants';

export const SavePriceDetail = () => {
  const titles = savecatalog.data.map((item) => item.title);
  const renderTooltip = (props: any) => {
    const { arrowProps, show, outOfBoundaries, scheduleUpdate, ...restProps } =
      props;
    return (
      <div
        {...restProps}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          padding: '2px 10px',
          color: 'white',
          borderRadius: 3,
          ...props.style,
        }}
      >
        {titles.join(', ')}
      </div>
    );
  };
  return (
    <React.Fragment>
      <p>
        系统自动计算结果基于如下参数：
        <br />
        <br />* 本计算公式只针对九个
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <span className='color-blue'>
            <i className='fas fa-exclamation-circle'></i>
            <b>开放品名</b>
          </span>
        </OverlayTrigger>
        <br />
        <br />
        <b>* 单价：${SAVE_SHIP_RATE}/kg， </b>{' '}
        <span className='text-muted'>
          {' '}
          单个包裹重量低于1kg的，按照1kg收费。
        </span>
        * 长宽高测量时均按最高点来测量，不足1cm的按1cm算
        <br />
        <br />
        * 不足100g的，向下取整收费。如：4.02kg收费重量为4kg。
        <br />
        超过100g但低于600g的，按照0.5kg收费。如：4.3kg收费重量为4.5kg。
        <br />
        超过600g对，向上取整收费。如：4.66kg收费重量为5kg。
        <br />
        <br />
        * 计费重量取抛货重量和实际重量中数值较大的一方：
        <br />
        抛货重量（公斤）计算公式：<b>长(cm) x 宽(cm) x 高(cm) / 6000</b>。
        <br />
        <br />
        <img src={packageVolume} className='img-fluid' alt='volume' />
        <br />
        <br />
        <span className='text-muted'>
          例如：长33厘米 x 宽33厘米 x 高33厘米 / 6000 = 5.9895 公斤。
          <br />
          运费：5.9895 公斤，取整数6公斤； 6公斤 x ${SAVE_SHIP_RATE}/kg = $
          {6 * SAVE_SHIP_RATE} 美元。
        </span>
      </p>
      <br />
      <p>
        <br />
        <br />
        <b>* 超长超重费 </b> （如有疑问请先咨询客服）：
        <br />
        <br />
        1）包裹单边长大于110cm但小于270cm，需加收$20/箱；
        <b>长 + 2 x（宽 + 高）大于330cm且不超过419cm，加收$100/箱</b>。
        <br /> <br />
        2）没有用任何东西包装或者用保鲜膜，布料，金属性的（如不锈钢）作为外包装或外包装是圆柱形需加收
        <b>$20/箱</b>。不接受木箱包装。
        <br />
      </p>
    </React.Fragment>
  );
};

export default SavePriceDetail;
