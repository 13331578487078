import React from 'react';

export const ForbiddenListSave = () => {
  return (
    <div className='table-responsive'>
      <table className='table table-striped table-sm'>
        <colgroup>
          <col />
          <col width={120} />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>#</th>
            <th>禁运类别</th>
            <th>禁运内容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>FDA产品</td>
            <td>
              食品药品保健品类，皮肤接触类产品如口杯，牙刷头、温度计、眼镜、纹眉笔、剃毛器，化妆品，内衣，婴儿产品，毛绒玩具等需FDA认证产品
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>侵权产品</td>
            <td>
              涉及侵犯欧美知识产权的产品、包装有蓝牙字样或有CE、HDMI、FCC、LACEY、
              ACT、 DOT标识的产品，三叉戟数据线
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>侵权书籍类</td>
            <td>
              任何英文书籍；美籍作家的中译本;印有国际知名卡通形象的书，如冰雪奇缘，Peppa
              Pig, Hello Kitty
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>侵权纺织品/包/鞋类</td>
            <td>
              国际知名品牌无论正品/仿牌/冒牌；印有国际知名卡通形象的，如冰雪奇缘，Peppa
              Pig, Hello Kitty
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>违禁品</td>
            <td>
              护照身份证等证件，国际品牌正品/仿牌/冒牌、平板电脑、手机、LCD屏、纯电池、手表、U盘、内存卡、光碟、液体、粉末、木箱包装、成人用品、指尖陀螺、刀类、打火机(包括USB打火机)，对讲机，移动电源，电子烟，彩印，硒鼓，墨盒，血压计，安全锤，手推车，钢钉，手表，铅笔，折叠型金属桌椅，宗教类及非法出版物，危险品，管制刀具及武器弹药，和木箱/铁箱等包装难以打开检查的货物
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>反倾销类产品</td>
            <td>
              蜡烛灯，铁钳合金，皱纹纸，天然猪鬃油漆刷及刷头，重煅造手工具，刹车转子，无可锻性铸铁管件，镁金属，铁制建筑铸件，纯镁，圆锥滚子轴承及部件，硅金属，折叠金属桌椅，草坪和花园铁制栅栏支架，彩色电视机，氯代异氰，碳钢焊接接口，落地式金属台面烫衣板及部件，石油蜡烛，钢筋，铸造焦碳，原聚酯棉印花布，螺旋形弹簧锁垫圈，定尺碳钢板，颗粒状纯镁，热轧碳钢板，棕刚玉，曲别针，搪瓷厨具，铅笔，硅锰合金，"可锻性玛钢管件"，咔唑紫颜料，手推车，钢钉，圆形焊接碳钢管件，新充气工程机械轮胎，低克重热敏纸，未加工橡胶磁，小直径石墨电极，圆形焊接奥氏体不锈高压套管，汽车大灯及汽车设备部件，贱金属及其制品(铁、钢、铜、镍、铝、铅、锌、锡、钨),
              化学产品，木制卧室家具，复合木地板，非晶硅织物，晶体硅光伏电池，小直径石墨电极，机械电气设备及部件，部分纸及纸制品，部分纺织制品-聚酯人造纤维，编织电热毯，复合编织袋，聚乙烯手提袋，矿石及矿物燃料，部分家用电器，自行车，车船运输设备及部件，建材与玻璃制品等{' '}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ForbiddenListSave;
