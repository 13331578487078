import { createContext } from 'react';
import { observable, action, computed } from 'mobx';
import IStation from '@interfaces/station';

export class StationStore {
  @observable stationMap: Map<string, IStation> = new Map();

  @computed get stations() {
    return [...this.stationMap.values()];
  }

  @action
  public saveStations(stations: IStation[]) {
    stations.forEach((station) => {
      const id = station.pcgGroupId;
      this.stationMap.set(id, station);
    });
  }
}

export const StationStoreContext = createContext(new StationStore());
