import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

export interface ImageLink {
  image: string;
  link: string;
  desc: string;
}

export interface ImageCarouselProps {
  links: ImageLink[];
}

const ImageCarousel = (props: ImageCarouselProps) => {
  const { links } = props;

  return (
    <Carousel>
      {links.map(({ link, image, desc }) => {
        return (
          <Carousel.Item key={link}>
            <a href={link}>
              <img className='d-block w-100' src={image} alt={desc} />
            </a>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default ImageCarousel;
