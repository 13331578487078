import { API } from 'aws-amplify';

let apiName = 'pcgWeb';
let path = '/groups';
let myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  queryStringParameters: {},
};

export const getGroups = async () => {
  const response = await API.get(apiName, path, myInit);
  return response.data.results.filter(
    ({ isClosed }: { isClosed: boolean }) => !isClosed,
  );
};
