import React from 'react';
import Introduction from './Introduction';
import Price from './Price';
import List from './List';

const Express = () => {
  return (
    <React.Fragment>
      <Introduction />
      <List />
      <Price />
    </React.Fragment>
  );
};

export default Express;
