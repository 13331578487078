import IAddress from '@interfaces/address';
export const isZipcode = (zipcode: string) => {
  return zipcode.length > 0 && !isNaN(Number(zipcode));
};

export const shortAddress = (address: IAddress) => {
  return [address.address, address.city, address.state, address.zipcode].join(
    ', ',
  );
};
