import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

import processSvg from '@assets/image/operations/process-storage-01.svg';
import { EXPRESS_SHIP_RATE } from 'constants/constants';

const Introduction = () => {
  return (
    <Container className='padding2em1em'>
      <Row>
        <Col sm={6} className='padding1em'>
          <img src={processSvg} className='img-fluid' alt='ship package' />
        </Col>
        <Col sm={6}>
          <h1>松果极速</h1>
          <p>
            松果app用户可以通过微信一键登陆来完成全部操作流程，简单方便萌萌哒。松果网的初衷是通过共享物流空间和本地商家合作的方式帮助用户完成中国至海外的逆向海淘,
            实现速度、价格和易操作三方面的最优化。基本实现与境内网购无差异的购物体验，随买随运，多快好省。
          </p>
          <ul className='h5 intro-list'>
            <li>无需凑单，小包裹100g起运</li>
            <li>
              运费 ${EXPRESS_SHIP_RATE} / 100g{' '}
              <span className='text-muted'>
                {' '}
                （即 每100克${EXPRESS_SHIP_RATE}美元）
              </span>
            </li>
            <li>仓库每周至少发货一次，随时买随时送</li>
            <li>
              空运3-7天到达{' '}
              <Link to={'/station'}>
                <i className='fas fa-store'></i> 松果取货小站
              </Link>
            </li>
            <li>单个包裹2kg及以上享受运费9折</li>
            <li>微信客服方便联系</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Introduction;
