import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ExpressPriceDetail from '@components/ExpressPriceDetail';
import SavePriceDetail from '@components/SavePriceDetail';
import PriceCalc from '@components/PriceCalc';

const PriceCalcView = () => {
  const expressTitle = (
    <h4>
      <i className='fas fa-plane-departure'></i> 极速空运 运费细则
    </h4>
  );
  const saveTitle = (
    <h4>
      <i className='fas fa-dolly-flatbed'></i> 极省海运 运费细则
    </h4>
  );

  return (
    <div className='padding2em'>
      <Container>
        <Row>
          <Col md={8}>
            <Tabs defaultActiveKey='express' id='PriceDetails'>
              <Tab eventKey='express' title={expressTitle}>
                <br />
                <ExpressPriceDetail />
              </Tab>
              <Tab eventKey='save' title={saveTitle}>
                <br />
                <SavePriceDetail />
              </Tab>
            </Tabs>
          </Col>
          <Col md={4}>
            <PriceCalc />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PriceCalcView;
