import React, { useState } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';
import IStation from '@interfaces/station';
import { shortAddress } from 'utils/location';

const apiKey = 'AIzaSyDvrF3TaEpU1z5GCTa13g1lXKJe8YahHTY';

export interface MapMarkersProps {
  stations: IStation[];
}

export const MapMarkers = withScriptjs(
  withGoogleMap((props: MapMarkersProps) => {
    const { stations } = props;
    let [open, setOpen] = useState(-1);
    return (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{ lat: 37.4173062, lng: -121.9931189 }}
      >
        {stations.map((station, index) => (
          <Marker
            position={{ lat: station.location.lat, lng: station.location.lng }}
            onClick={() => setOpen(index)}
          >
            {open === index && (
              <InfoWindow>
                <p>
                  <h3>{station.name}</h3>
                  <h5>{station.hours}</h5>
                  <h5>{shortAddress(station.address)}</h5>
                </p>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    );
  }),
);

export const MapView = (props: MapMarkersProps) => {
  return (
    <MapMarkers
      {...props}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `400px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
};

export default MapView;
