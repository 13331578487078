import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

const List = () => {
  return (
    <div className='padding2em1em'>
      <Container className='tab-info'>
        <Row>
          <Col>
            <Tabs defaultActiveKey='book' id='importList'>
              <Tab eventKey='book' title='图书'>
                <p className='padding2em1em'>
                  松果极省图书线以印刷出版物为主，方便大家学习中文和阅读中文类优秀著作。{' '}
                  <b>注意：因为版权问题，大部分境外英文原版类图书都不能运</b>{' '}
                  ，如有特别情况，如原版作者为中国人的，请携带ISBN码询问客服。
                  <br />
                  <br />
                  具体可运：
                  <br />
                  1）大部分的<b>中文原版书籍</b>：
                  中国作者著儿童绘本、中小学语数教辅书、练习本、书法字帖、国学，新华字典、汉字卡，学前教育（四五快读），中国作家著文学作品
                  <br />
                  <br />
                  2）有声读物类： 爱看屋/洪恩品牌点读笔+配套书本套餐，有声挂图{' '}
                  <b>
                    （贝灵点读笔/读书郎迪士尼点读笔书本涉境外卡通形象不可运，hello
                    kitty品牌涉海外卡通形象不可运，其他品牌运前请咨询松果客服确认。）
                  </b>
                  <br />
                  <br />
                  3）企业宣传册类： 企业宣传和内部分发的印刷册
                  <br />
                  <br />
                  <b>4）禁运列表：</b>
                  宗教反动类，盗版书籍类，含非大陆原创卡通形象的绘本书籍(如米菲兔系列，迪士尼系列)，外国作家著中译版书籍包括儿童绘本、计算机类书籍、工具书、文学作品（哈利波特，Python中译本
                  ），GRE/TOFEL等英语考试辅导书。
                </p>
              </Tab>
              <Tab eventKey='bedroom' title='寝具'>
                <p className='padding2em1em'>
                  床上四件套, 被子枕头坐垫。
                  <br />
                  梦里寻他千百度，蓦然回首还是国内被子最舒服。
                  <br />
                  <br />
                  具体可运：
                  成品被子（只运棉胎不行），手工被，子母被，儿童防踢被，
                  海绵垫子，塑料充气坐垫，床罩，全棉床上四件套，抱枕套，枕芯，抱枕，其它坐垫
                  <br />
                  <br />
                  <b>禁运列表：</b>
                  含有侵权图案：如一线品牌LOGO/海外知名卡通形象/世界名画图案/名人肖像
                </p>
              </Tab>
              <Tab eventKey='decorator' title='布艺软饰'>
                <p className='padding2em1em'>
                  窗帘浴帘, 餐桌沙发织物。
                  <br />
                  生活的小点睛往往来自于客厅一角那抹与众不同的花色。松果极省线，让你再也不用因为运费顾虑而放弃个性定制窗帘类软的机会啦。
                  <br />
                  <br />
                  具体可运：
                  布艺窗帘，门帘，浴帘，帐幔，餐桌垫，桌布，桌旗，布艺沙发套，椅套等
                  <br />
                  <br />
                  <b>禁运列表：</b>
                  含侵权图案（如一线品牌LOGO/海外知名卡通形象/世界名画图案）布艺制品
                </p>
              </Tab>
              <Tab eventKey='steel' title='厨房铁质用具'>
                <p className='padding2em1em'>
                  铸铁类／不锈钢类锅具, 不锈钢烘培模具, 不锈钢类保温设备。
                  <br />
                  每一个厨神的背后都有一口好锅，每一个巧妇的厨房都不缺神器。好的锅再沉也无妨，交给松果极省线吧！
                  <br />
                  <br />
                  具体可运：
                  餐桌、厨房等家用铸铁制器具。如铸铁或不锈钢类锅具/铲勺、不锈钢保温瓶/保温杯、不锈钢／铁质点心模具。
                  <br />
                  <br />
                  <b>禁运列表：</b>
                  带电类产品，即所有带电源插座的产品。含有侵权图案：海外知名卡通形象/世界名画图案/名人肖像
                </p>
              </Tab>
              <Tab eventKey='coat' title='防寒外套'>
                <p className='padding2em1em'>
                  毛呢大衣, 女士羽绒服。
                  <br />
                  美丽不“冻人”，这个冬天让松果极省为温暖护航。
                  <br />
                  <br />
                  具体可运：
                  各类羽绒服（可带水貂皮装饰），女式毛呢大衣（可带狐狸毛装饰），斗篷，披肩。
                  <br />
                  <br />
                  <b>禁运列表：</b>
                  含海外知名品牌LOGO/商标的无论正品/仿品/尾单，印有海外知名卡通形象如Thomas
                  & Friends。
                </p>
              </Tab>
              <Tab eventKey='suit' title='婚纱裙装'>
                <p className='padding2em1em'>
                  婚纱, 礼服等。
                  <br />
                  无论是梦想的婚礼还是绚丽的晚会，都不能缺少一件独一无二适合自己的礼服。松果极省对这一切说”yes”。
                  <br />
                  <br />
                  具体可运：
                  婚纱类，汉服旗袍，晚礼服，芭蕾舞裙等所有非真丝全棉类女式/女童裙装。
                  <br />
                  <br />
                  <b>禁运列表：</b>
                  真丝全棉类，含海外知名品牌LOGO/商标的无论正品/仿品/尾单，印有海外知名卡通形象如Hello
                  Kitty
                </p>
              </Tab>
              <Tab eventKey='plastic' title='塑料家具'>
                <p className='padding2em1em'>
                  各种塑料类储物/桌柜。
                  <br />
                  收纳是个永恒的话题，没有格子的橱柜不是好橱柜～嵌入式橱柜或卡通橱柜都可以有更多样化的选择，当运费极低时，任性就是这么简单。
                  <br />
                  <br />
                  具体可运： 塑料制储物柜
                  (可拼接衣柜，鞋柜)，储物架／盒（乐高收纳柜，首饰盒），乐高陈列柜，折叠电脑桌。
                  <br />
                  <br />
                  <b>禁运列表：</b> 印有海外知名卡通形象如迪士尼卡通形象，Hello
                  Kitty，Thomas & Friends等。\n包裹最长边不超过110cm
                </p>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default List;
