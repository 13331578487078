import React from 'react';
import IAddress from '@interfaces/address';

export interface AddressProps {
  address: IAddress;
  short?: boolean;
}

export const Address = (props: AddressProps) => {
  const { short, address } = props;
  if (short) {
    return (
      <div>{`${address.address} ${address.city} ${address.state} ${address.zipcode}`}</div>
    );
  }
  return (
    <div>
      <div>
        <i className='fas fa-map-marker-alt paddinghalfright'></i>
        {address.address}{' '}
      </div>
      <div>
        {address.city}, {address.state}, {address.zipcode}
      </div>
    </div>
  );
};

export default Address;
