import React, { useContext, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StationStoreContext } from '@store/station';
import { isZipcode } from 'utils/location';
import MapView from '@components/MapMarkers';

const searchPlaceHolder = '输入zipcode或者城市名查找小站';

const Map = () => {
  const [input, setInput] = useState('');
  const stationStore = useContext(StationStoreContext);
  const stations = stationStore.stations;
  let filteredStations = stations;

  const changeInputValue = (event: React.FormEvent<FormControlProps>) => {
    const { value } = event.target as HTMLInputElement;
    setInput(value);
  };

  if (input) {
    filteredStations = stations.filter((station) => {
      if (isZipcode(input)) {
        return station.zipcode.startsWith(input);
      } else {
        return station.address.city
          .toLowerCase()
          .startsWith(input.toLowerCase());
      }
    });
  }

  return (
    <div className='padding2em1em' id={'stations'}>
      <Container>
        <Row>
          <Col>
            <InputGroup className='mb-3'>
              <FormControl
                placeholder={searchPlaceHolder}
                aria-label={searchPlaceHolder}
                aria-describedby='basic-addon1'
                onChange={changeInputValue}
              />
              <InputGroup.Append>
                <Button variant='warning'>查找附近小站</Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <MapView stations={filteredStations} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Map;
