import React from 'react';

export const ForbiddenList = () => {
  return (
    <div className='table-responsive'>
      <table className='table table-striped table-sm'>
        <thead>
          <tr>
            <th>#</th>
            <th>禁运类别</th>
            <th>禁运原则</th>
            <th>禁运实例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>液体</td>
            <td>水/乳液/霜/露/啫喱/胶/膏/泥状物</td>
            <td>
              洁面奶/啫喱，卸妆油/水，化妆水，乳液，面霜，眼霜，润肤露，面膜泥，磨砂膏，睫毛膏，粉底液，唇彩，眼线液，指甲油，香体露/滚珠，香水，压缩泡沫/喷雾类如防晒泡沫/喷雾，压缩止汗剂，定型摩丝等
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>个人护理品</td>
            <td>护理液，发膜，牙膏，发胶啫喱，刮胡泡等</td>
            <td> </td>
          </tr>
          <tr>
            <td>3</td>
            <td>国际品牌/仿牌</td>
            <td>
              <b>国际大牌不论正品或者仿品均不承运</b>
            </td>
            <td>
              LV, Chanel, Prada, Fendi, Gucci, Dior, Hermes, Balenciaga, Coach,
              Chloe, Valentino, Givenchy, Celine, Bottega Veneta, Michael Kors
              (MK), Kate Spade，Rolex等
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>粉末状物品</td>
            <td>不论任何颜色的粉末</td>
            <td>爽身粉，浴盐，足用药粉，沙子，中药粉，颜料粉，奶粉等</td>
          </tr>
          <tr>
            <td>5</td>
            <td>带电带强磁物品</td>
            <td>
              内置电池类物品及未加消磁防护包装的<b>强磁性</b>物品
            </td>
            <td>充电宝，纯电池，带大容量内置电池的产品，磁铁，磁性墙贴等</td>
          </tr>
          <tr>
            <td>6</td>
            <td>药品/保健品/食品</td>
            <td>任何药品,保健品和食品</td>
            <td>
              非处方/处方药，中药/中成药，保健品，食物，零食，含肉制品，干货类，水产海产，人参燕窝等
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>媒体出版物</td>
            <td>非法/盗版出版物、印刷品、音像制品等宣传品</td>
            <td>
              盗版书籍、软件和音像制品，<b>内容涉及国家秘密的物品</b>
              ，反动、淫秽、法轮功等的书籍及音像制品等
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>管制刀具及武器弹药</td>
            <td>管制刀具，武器弹药及各种仿真武器</td>
            <td>
              仿真玩具枪，菜刀，斩骨刀，匕首、弹簧刀，收藏刀、竞技类刀剑，瞄准镜等
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>国家货币，证券，贵重金银珠宝以及古玩</td>
            <td>国家货币/证券以及伪造的货币/证券，金银珠宝，古玩</td>
            <td>
              美元，人民币，证券，伪造的货币及证券；金条，金币，翡翠玉镯，钻石/宝石制品，象牙制品，古玩，珍贵文物等
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>文件类</td>
            <td>真实以及伪造类证件文书</td>
            <td>真实以及伪造类证件文书</td>
          </tr>
          <tr>
            <td>11</td>
            <td>各种危险品</td>
            <td>易燃易爆、腐蚀性、毒性、强酸碱性和放射性物品</td>
            <td>
              压缩气体/干冰，带气打火机，活性炭，香薰，有机溶剂，含酒精成分的产品等
            </td>
          </tr>
          <tr>
            <td>12</td>
            <td>海关限制的其他类物品</td>
            <td>大陆出口违法禁寄的物品以及各国海关禁止进口的物品</td>
            <td>
              生物化学制品，植物及种子类，宗教类物品，烟草，违反知识产权和注册商品的物品，间谍专用器材等
            </td>
          </tr>
          <tr>
            <td>13</td>
            <td>其它</td>
            <td>备注</td>
            <td>
              1. Nike & Adidas品牌运动产品暂时均不能承运
              <br />
              2. <b>玻璃、陶瓷、工艺品等易碎物品不建议邮寄。</b>
              用户实有需求且在同意免责条款后方予承运，但承运过程中发生的破损情况用户自行承担后果，松果不予赔偿及退还运费
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ForbiddenList;
