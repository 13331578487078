import React from 'react';
import Home from './Home';
import Introduction from './Introduction';
import Warehouse from './Warehouse';
import Shipment from './Shipment';
import Benefit from './Benefit';
// import Stations from './Stations';
// import Feedbacks from './Feedbacks';
// import Recommendation from './Recommendations';
import Download from './Download';
import Steps from './Steps';

const Content = () => {
  return (
    <React.Fragment>
      <Home />
      <Introduction />
      <Steps />
      <Warehouse />
      <Shipment />
      <Benefit />
      {/* <Stations /> */}
      {/* <Recommendation /> */}
      {/* <Feedbacks /> */}
      <Download />
    </React.Fragment>
  );
};

export default Content;
