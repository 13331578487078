import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import bgImage from '@assets/image/branding/womanPack2.png';
import iosStore from '@assets/image/download/ios_store.png';
import androidStore from '@assets/image/download/android_store.png';
import androidQr from '@assets/image/download/androidQRcode.png';
import iosQr from '@assets/image/download/iOSqrCode.png';

const Download = () => {
  return (
    <div
      id='download'
      className='bg-img padding2em1em'
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <Container>
        <div className='margin3auto' style={{ width: 300 }}>
          <h3>
            <i className='fas fa-arrow-alt-circle-down'></i> 欢迎下载手机App
          </h3>
        </div>
        <Row>
          <Col>
            <div className='download'>
              <img src={iosStore} alt='ios qr' />
              <br />
              <a href='https://itunes.apple.com/us/app/pineconego-%E6%9D%BE%E6%9E%9C%E7%BD%91/id1441396496?mt=8'>
                <img src={iosQr} alt='ios store' />
              </a>
            </div>
          </Col>
          <Col>
            <div className='download'>
              <img src={androidStore} alt='android qr' />
              <br />
              <a href='https://play.google.com/store/apps/details?id=com.pineconego.android'>
                <img src={androidQr} alt='android store' />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Download;
