import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import likeUsSVG from '@assets/image/operations/process-online-01.svg';
const link =
  'https://docs.google.com/forms/d/e/1FAIpQLScNAvggEgvfSx4ldHAda2lcRbcGlFTEFtbkgqK1DOjPM9_8Jg/viewform?vc=0&c=0&w=1&flr=0&gxids=7628';

export const Invite = () => {
  return (
    <div className='padding2em'>
      <Container>
        <Row>
          <Col sm={6} className='padding1em'>
            <img
              src={likeUsSVG}
              className='img-fluid'
              alt='like us and join us'
            />
          </Col>
          <Col sm={6}>
            <h1>喜欢我们，加入我们</h1>
            松果网是年轻的初创公司，我们竭诚服务我们的客户，为你带来跨境购物的方便和实惠。如果您喜欢我们的运营模式，希望成为我们松果取货的站点，请填写
            <a href={link} target='_blank' rel='noopener noreferrer'>
              加盟申请表
            </a>
            。我们将尽快联系您。
            {/* <dt>加盟为业务代理</dt>
              <dd>
                目前松果服务用户主要为湾区。如果您希望和我们合作，一起拓展业务至您所居住的华人聚居区，也可填写
                <a href={link}>加盟申请表</a>。我们期待于您的合作。
              </dd> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Invite;
