import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import phone from '../../assets/image/branding/phoneMockup2.png';

const Benefit = () => {
  return (
    <div className='bg-yellow'>
      <Container className='padding2em1em'>
        <Row>
          <Col md className='padding2em'>
            <img src={phone} alt={'App screenshot'} className='img-fluid' />
          </Col>
          <Col md className='padding2em text-white'>
            <ul className='list-unstyled'>
              <li className='media'>
                <i className='fas fa-cart-arrow-down fa-3x'></i>
                <div className='media-body padding1left'>
                  <h5 className='mt-0 mb-1'>易操作</h5>
                  易操作，微信一键登录，全自助服务系统。
                </div>
              </li>
              <li className='media my-4'>
                <i className='fas fa-store fa-3x'></i>
                <div className='media-body padding1left'>
                  <h5 className='mt-0 mb-1'>取货便</h5>
                  取货便，大华超市附近门店取货，FLAG等各大公司快闪取货。
                </div>
              </li>
              <li className='media'>
                <i className='fas fa-boxes fa-3x'></i>
                <div className='media-body padding1left'>
                  <h5 className='mt-0 mb-1'>无首重</h5>
                  无首重，最低100g计费。密切追踪包裹位置，实时更新订单信息。
                </div>
              </li>
              <li className='media my-4'>
                <i className='fas fa-shipping-fast fa-3x'></i>
                <div className='media-body padding1left'>
                  <h5 className='mt-0 mb-1'>到货快</h5>
                  每周运送，最快3天送达，让你随时买快速收。
                </div>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Benefit;
