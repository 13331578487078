import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Warehouse = () => {
  return (
    <div className='bg-yellow'>
      <Container className='bg-yellow index-address text-white'>
        <Row>
          <Col>
            <b>
              <i className='fas fa-warehouse'></i> - 松果收件仓库
            </b>

            <div className='margin1top'>
              <div>
                地址：中国 广州市 白云区 龙归街道 太和镇 广从二路268号
                汇雅花园内汇雅街138号
              </div>
              <div>电话：+86 13250743412</div>
              <div>收件：真实姓名SG （王小明SG）</div>
              <div>邮编：510420</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <b>
              <i className='fas fa-dolly-flatbed'></i> - 仓库服务内容
            </b>
            <div className='margin1top'>
              <div>
                商品确认收货 / 拆除多余商品包装 / 压缩纺织品体积 / 转运国内 /
                追踪货运信息 / 实时更新运输动态 /
                通过客服联系关于包裹的其它处理信息
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Warehouse;
