import React from 'react';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ListGroup from 'react-bootstrap/ListGroup';
import { IStation } from '@interfaces/station';
import Address from './Address';
import ContanctInfo from './ContactInfo';

export const Station = (props: { station: IStation }) => {
  const station = props.station;
  return (
    <Card className='border-warning'>
      <Card.Img variant='top' src={station.icon} />
      <Card.Body>
        <Card.Title>
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id='stationId'>{station.description}</Tooltip>}
          >
            <span>{station.name}</span>
          </OverlayTrigger>
        </Card.Title>
        <Card.Text>
          <Address address={station.address} />
        </Card.Text>
      </Card.Body>
      <ListGroup className='list-group-flush'>
        <ListGroup.Item>服务时间: {station.hours}</ListGroup.Item>
        <ListGroup.Item>
          <ContanctInfo contactInfo={station.contactInfo} />
        </ListGroup.Item>
      </ListGroup>
      {/* <Card.Footer>
        <small className='text-muted'>
          <i className='fas fa-clock'></i> 距离发货还有2天13小时37分钟
        </small>
      </Card.Footer> */}
    </Card>
  );
};

export default Station;
