import React from 'react';
import { Contact } from '@interfaces/station';

export interface ContantInfoProps {
  contactInfo: Contact;
}

export const ContantInfo = (props: ContantInfoProps) => {
  const { name, phone, wechatId } = props.contactInfo;
  return (
    <div>
      联系人: {name} {phone || `微信号(${wechatId})`}
    </div>
  );
};

export default ContantInfo;
