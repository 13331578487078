import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ImageCarousel from '@components/ImageCarousel';

import coverLinks from '@assets/data/cover.json';

const Home = () => {
  return (
    <div className='bg-image-green'>
      <Container className='flex-container padding2em'>
        <Row className={'color-yellow'}>
          <Col xs={12} md={4}>
            <div className='margin3auto'>
              <h1 className='text-candal'>
                <b>松果网</b>PineconeGo
              </h1>
              <h5>淘宝京东 随买随寄</h5>
              <h5>The best forwarder from China to your place at fingertips</h5>
            </div>
          </Col>
          <Col xs={12} md={8} className='flex-align-self-end'>
            <ImageCarousel links={coverLinks.data} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
